import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Byteaffairs - Partners for your Digital Journey ', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description:
    'We are a start-up of young professionals who love building digital products, tools and digital transformation! We offer service from Software Development to Cloud Transformation.',
};

// HERO DATA
export const heroData = {
  title: ' ',
  name: 'ByteAffairs,',
  subtitle: 'Partners for your Digital Journey',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'new2.jpg',
  paragraphOne:
    'We are a team of web engineers and business analysts who love building digital products and tools! We offer service from Software Development, Online Marketing, SEO/SEA to Cloud Transformation.',
  paragraphTwo: ' ',
  paragraphThree: ' ',
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'contact@byteaffairs.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/amin-hofmann-58992915a/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/ahfmnn',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
